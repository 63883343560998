<template>
  <router-view />
</template>
<script>

export default {
  name: 'DashboardUsers',
  data: () => ({

  })
}
</script>
<style lang="scss">
.col .card {
  min-height: 100%;
}
</style>